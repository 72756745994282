import React from "react"

import SEO from "../components/seo"

export default () => (
  <>
    <SEO
      title="404"
      description="Page not found"
      type="article"
      image={null}
      pathname={null}
      robots={`noindex, follow`}
    />
    <section className="p-4 pt-0" style={{ marginTop: "86px" }}>
      <div className="text-center">
        Oops! The page you are looking for has been removed, relocated, or
        doesn't exist.
      </div>
    </section>
  </>
)
